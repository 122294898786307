<template>
  <div class="row">
    <div class="col-12">
      <c-table
        ref="table"
        title="개선 목록"
        tableId="impr01"
        :columns="gridImpr.columns"
        :data="riskReduce.imprs"
        :merge="gridImpr.merge"
        :gridHeight="setheight"
        @linkClick="linkClick"
      >
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='riskHazardPicture'">
            <q-icon
              style="font-size: 30px; cursor:pointer;"
              class="text-success" 
              name="image"
              @click.stop="openHazardPicture(props)" /> 
            <!-- <c-upload-picture
              class="scenario4m"
              height="35px"
              :attachInfo="{
                isSubmit: '',
                taskClassCd: 'RISK_HAZARD',
                taskKey: props.row.ramRiskHazardId,
              }"
            ></c-upload-picture> -->
          </template>
          <template v-else-if="col.name==='picture'">
            <q-icon
              style="font-size: 30px; cursor:pointer;"
              class="text-primary" 
              name="image"
              @click.stop="openImprPicture(props)" />  
          </template>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'fm-impr',
  props: {
    riskReduce: {
      type: Object,
      default: function() {
        return {
          imprs: [],
          riskbooks: [],
        }
      },
    },
    param: {
      type: Object,
      default: () => ({
        vendorFlag: false,
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      gridImpr: {
        merge: [
          { index: 0, colName: 'processName' },
          { index: 1, colName: 'groupMdmSopId' },
          { index: 2, colName: 'ram4mRiskHazardClassGroup' },
          { index: 3, colName: 'ram4mAssessScenarioId' },
          { index: 4, colName: 'ram4mAssessScenarioId' },
          { index: 5, colName: 'ram4mAssessScenarioId' },
          { index: 6, colName: 'ram4mAssessScenarioId' },
          { index: 7, colName: 'ram4mAssessScenarioId' },
          { index: 8, colName: 'ram4mAssessScenarioId' },
        ],
        columns: [],
        data: [],
        height: '500px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      editable: true,
      imprData: null,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    setheight() {
      let pxRemove = this.height.replace('px', '');
      return this.height && this.height.indexOf('px') > -1 && !isNaN(pxRemove) ? (Number(pxRemove) - 60) + 'px' : '500px'
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      if (!this.param.vendorFlag) {
        this.$comm.getStepItems('IBM_STEP_CD').then(_result => {
          this.gridImpr.columns = [
            {
              name: 'processName',
              field: 'processName',
              label: '공정',
              align: 'left',
              style: 'width:100px',
              sortable: false,
            },
            {
              name: 'jobName',
              field: 'jobName',
              label: '작업',
              align: 'left',
              style: 'width:150px',
              sortable: false,
            },
            {
              name: 'ram4mRiskHazardClassName',
              field: 'ram4mRiskHazardClassName',
              label: '평가구분(4M)',
              align: 'center',
              style: 'width:90px',
              sortable: false,
            },
            {
              name: 'riskHazardPicture',
              field: 'riskHazardPicture',
              label: '사진',
              align: 'center',
              style: 'width:40px',
              sortable: false,
              type: 'custom',
            },
            {
              name: 'riskHazardName',
              field: 'riskHazardName',
              label: '유해위험요인',
              align: 'left',
              style: 'width:170px',
              sortable: false,
            },
            {
              name: 'relationLaw',
              field: 'relationLaw',
              label: '관련법규',
              align: 'left',
              style: 'width:120px',
              sortable: false,
            },
            {
              name: 'damageTargetName',
              field: 'damageTargetName',
              label: '피해대상',
              align: 'left',
              style: 'width:120px',
              sortable: false,
            },
            {
              name: 'damageTypeName',
              field: 'damageTypeName',
              label: '재해형태',
              align: 'left',
              style: 'width:90px',
              sortable: false,
            },
            {
              name: 'existingRiskManagementActivities',
              field: 'existingRiskManagementActivities',
              label: '현재안전조치',
              align: 'left',
              style: 'width:180px',
              sortable: false,
            },
            {
              name: 'picture',
              field: 'picture',
              label: '개선 전/후 사진',
              align: 'center',
              type: 'custom',
              style: 'width:60px',
              sortable: false,
            },
            {
              name: 'addingRiskManagementActivities',
              field: 'addingRiskManagementActivities',
              label: '추가 리스크관리 계획',
              align: 'left',
              style: 'width:180px',
              sortable: false,
            },
            {
              name: 'ibmTitle',
              field: 'ibmTitle',
              label: '제목',
              align: 'left',
              type: 'link',
              style: 'width:200px',
              sortable: true,
            },
            {
              name: 'ibmStepCd',
              field: 'ibmStepCd',
              label: '진행상태',
              align: 'center',
              style: 'width:80px',
              type: 'tag',
              colorItems: _result,
              sortable: false
            },
            {
              name: 'improveRequestDeptName',
              field: 'improveRequestDeptName',
              label: '요청부서',
              align: 'center',
              style: 'width:100px',
              sortable: true,
            },
            {
              name: 'actionDeptName',
              field: 'actionDeptName',
              label: '조치부서',
              align: 'center',
              style: 'width:100px',
              sortable: true,
            },
            {
              name: 'actionCompleteRequestDate',
              field: 'actionCompleteRequestDate',
              label: '조치완료요청일',
              align: 'center',
              style: 'width:100px',
              sortable: true,
            },
          ]
        });
      } else {
        this.gridImpr.columns = [
          {
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'left',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '작업',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'ram4mRiskHazardClassName',
            field: 'ram4mRiskHazardClassName',
            label: '평가구분(4M)',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'riskHazardName',
            field: 'riskHazardName',
            label: '유해위험요인',
            align: 'left',
            style: 'width:170px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'relationLaw',
            field: 'relationLaw',
            label: '관련법규',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'damageTargetName',
            field: 'damageTargetName',
            label: '피해대상',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'damageTypeName',
            field: 'damageTypeName',
            label: '재해형태',
            align: 'left',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'existingRiskManagementActivities',
            field: 'existingRiskManagementActivities',
            label: '현재안전조치',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'picture',
            field: 'picture',
            label: '개선 전/후 사진',
            align: 'center',
            type: 'custom',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'addingRiskManagementActivities',
            field: 'addingRiskManagementActivities',
            label: '추가 리스크관리 계획',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            type: 'link',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'stepFlagName',
            field: 'stepFlagName',
            label: '진행상태',
            align: 'center',
            style: 'width:100px',
            sortable: false
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            label: '조치일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ]
      }
      // list setting
    },
    research() {
      this.$emit('research');
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = '개선';
      if (!this.param.vendorFlag) {
        this.popupOptions.param = {
          sopImprovementId: row.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      } else {
        this.popupOptions.param = {
          ram4mAssessImprId: row.ram4mAssessImprId,
        };
        this.popupOptions.target = () => import(`${'@/pages/ram/4m/risk/4mImprRiskReduceDetail.vue'}`);
      }
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        this.$_.extend(this.imprData, result.col1)
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.research();
      }
    },
    openHazardPicture(props) {
      this.popupOptions.title = '유해위험요인 사진'; 
      this.popupOptions.param = {
        ramRiskHazardId: props.row.ramRiskHazardId,
        disabled: true
      };
      this.popupOptions.target = () => import(`${'@/pages/common/hazard/riskHazardPicture.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeHazardPicturePopup;
    },
    closeHazardPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    openImprPicture(props) {
      this.popupOptions.title = '개선 사진'; 
      this.popupOptions.param = {
        ram4mAssessScenarioId: props.row.ram4mAssessScenarioId,
        sopImprovementIds: props.row.sopImprovementIds,
        ram4mAssessImprIds: props.row.ram4mAssessImprIds,
        disabled: true
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/4m/action/4mScenarioImprPicture.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeScenarioImprPicturePopup;
    },
    closeScenarioImprPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>